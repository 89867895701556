import map from 'lodash/map';

/**
 * Objeto do tipo booleano
 * @param label
 * @param defaultValue
 * @param columns
 * @returns {{type: string, default: boolean, ui: {label: *, widgetConfig: {enumSource: *[]}}}}
 */
export function schemaBoolean(label, defaultValue = true, columns = 12, useNumbers = false) {
  return {
    type: 'boolean',
    default: defaultValue,
    ui: {
      label,
      columns,
      "widgetConfig": {
        "enumSource": [
          {
            "label": "Sim",
            "value": useNumbers ? 1 : true
          },
          {
            "label": "Não",
            "value": useNumbers ? 0 : false
          }
        ]
      },
    }
  }
}

/**
 * Objeto do tipo booleano ( com caixa de seleção Sim/Não )
 * @param label
 * @param required
 * @param columns
 * @param useNumbers
 * @param defaultValue
 * @returns {{type: string, default: boolean, ui: {label: *, widgetConfig: {enumSource: *[]}}}}
 */
export function schemaBooleanSelect(label, required = true, columns = 12, useNumbers = false, defaultValue = true) {
  return {
    type: 'boolean',
    default: useNumbers
      ? (defaultValue ? 1 : 0)
      : defaultValue,
    ui: {
      widget: "select",
      label,
      columns,
      "widgetConfig": {
        placeholder: 'Selecione',
        "enumSource": [
          {
            "label": "Sim",
            "value": useNumbers ? 1 : true
          },
          {
            "label": "Não",
            "value": useNumbers ? 0 : false
          }
        ]
      },
    },
    rules: getRules({ required })
  }
}


/**
 * Objteto do tipo texto
 * @param label
 * @param required
 * @param columns
 * @param options
 * @param defaultValue
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaSelect(label, required = false, columns = 12, options = {}, defaultValue = null) {
  let enumSource = [];

  map(options, (label, value) => {
    enumSource.push({ label, value });
  });

  return {
    type: 'string',
    default: defaultValue,
    ui: {
      widget: "select",
      widgetConfig: {
        placeholder: 'Selecione',
        enumSource,
      },
      label,
      columns,
    },
    rules: getRules({ required })
  }
}


/**
 * Objteto do tipo texto
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaTextField(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objteto do datePicker
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaDatePicker(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      widget: "InputDatePicker",
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objteto do tipo percentual
 * @param label
 * @param required
 * @param columns
 * @param defaultValue
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaPercent(label, required = false, columns = 12, defaultValue = null) {
  return {
    type: 'string',
    default: defaultValue,
    ui: {
      widget: 'InputPercent',
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objteto do tipo monetário
 * @param label
 * @param required
 * @param columns
 * @param defaultValue
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaMoney(label, required = false, columns = 12, defaultValue=null) {
  return {
    type: 'string',
    dfault: defaultValue,
    ui: {
      widget: 'InputMoney',
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objeto do tipo textArea
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaTextArea(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      widget: 'textarea',
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objeto do tipo editor de SQL
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaSqlEditor(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      widget: "codeblock",
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objteto do tipo texto
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaColorPicker(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      widget: 'form-input-color-picker',
      label,
      columns,
      widgetConfig: {
        // name: "joão"
      }
    },
    rules: getRules({ required })
  }
}

/**
 * Objeto do tipo númro
 * @param label
 * @param required
 * @param columns
 * @param defaultValue
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaNumber(label, required = false, columns = 12, defaultValue = null) {
  return {
    type: 'number',
    default: defaultValue,
    ui: {
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Obtém objeto de regras
 * @param rules
 */
function getRules(rules) {
  let _rules = {};

  if (rules.required) {
    _rules['required'] = {
      "value": true,
      "errMsg": "Campo de preenchimento obrigatório"
    }
  }

  return _rules;
}